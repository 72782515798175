@import url("../src/assets/CSS/Header.css");
@import url("../src/assets/CSS/Home.web.css");
@import url("../src/assets/CSS/Contact.web.css");

body{
  font-family: "Nunito",sans-serif !important;
  /* background: #f4feff; */
}
img{
  width: 100%;
}
.App {
  text-align: center;
  /* background-color: #f4feff; */
}
.spinner {
  display: flex;
  width: 100% !important;
  height: 100vh !important;
  background: #f4feff6e;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  /* opacity: 0.2; */
  overflow: hidden;
  z-index: 9999;
}
.div_main_loader{
  position: relative;
}
.loader_main_img{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.loader_Set{
  position: absolute;
  width: 40px;
  height: 38px;
  top: 42%;
  left: 41%;
}
p {
  font-family: "Poppins",sans-serif;
  color: #707b8e;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}
li {
  list-style: none;
}
ul {
  margin: 0px;
  padding: 0px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
