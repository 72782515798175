.logo {
  width: 45px;
  height: 40px;
}

.navbar {
  padding-bottom: 0.6rem;
}

.header_main {
  height: 60px;
}

.nav_class {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.nav_main {
  background: #f4feff;
}

.nav_class .nav-item .nav-link {
  /* margin: 0 50px 0 0; */
  color: #450b78;
  font-weight: 600 !important;
  padding: 26px 26px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  text-transform: capitalize;
}

.nav_class .nav-item .nav-link:hover {
  border-bottom: 2px solid #450b78;
  color: #450b78;
  transition: .3s;

}

.nav-link_underline {
  /* margin: 0 50px 0 0; */
  color: #450b78;
  font-weight: 600 !important;
  padding: 26px 26px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  text-transform: capitalize;
  border-bottom: 2px solid #450b78;
  color: #450b78;
}

.nav_item_active {
  /* margin: 0 50px 0 0; */
  color: #450b78;
  font-weight: 400;
  padding: 26px 16px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  text-transform: capitalize;
  border-bottom: 2px solid #450b78;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
  background: #fff;
  z-index: 999;
  box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* -----aboutUS */
.about_us {}

.about_us p {
  text-align: justify;
  font-size: 22px;
  margin: 0 0 41px 0;
  line-height: 2;
  letter-spacing: 1px;
}

@media only screen and (max-width: 991px) {
  .sticky {
    position: unset;
  }

  .header_main {
    height: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .sticky {
    position: unset;
  }

  .header_main {
    height: 100%;
  }
}