.contact_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Nunito", sans-serif;
      /* margin: 120px 0px; */

}

.services-area {
      width: 100%;
      font-size: 50px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 2px;
      color: #2b044d;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      margin: 60px 0px;
}

.contact_form_box {
      width: 100%;
      text-align: left;
}

.contact-title {
      font-size: 30px;
      line-height: 26px;
      color: #2b044d;
}

.map-area {
      width: 100%;
      margin: 50px 0px;
}

.contact_flex_form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap !important;
}

.contact_form {
      width: 60%;
      flex-basis: auto;
}

.form_control_textarea {
      width: 700px;
      margin-top: 20px;
      border: 1px solid #ced4da;
}

.name_email_group {

      margin-top: 20px;


}

.form_control_name {
      width: 330px;
      padding: 18px 5px;
      border: 1px solid #ced4da;
}

.form_control_email {
      width: 330px;
      /* margin-left: 40px; */
      padding: 18px 5px;
      border: 1px solid #ced4da;
}

.form_control_subject {
      width: 700px;
      padding: 18px 5px;
      margin-top: 20px;
      border: 1px solid #ced4da;
}

.contact_form_btn {
      margin-top: 20px;
      background: #fff;
      color: #8f1bdc !important;
      display: inline-block;
      padding: 18px 44px;
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      font-weight: 400;
      border: 0;
      border: 1px solid #8f1bdc;
      letter-spacing: 3px;
      text-align: center;
      color: #8f1bdc;
      text-transform: uppercase;
      cursor: pointer;
}

.contact_form_btn:hover {
      background: #8f1bdc;
      color: #fff !important;

}

.contact_info_data {
      width: 40%;
      margin-top: -10px;
      padding-left: 10px;
}

.media_contact-info {
      width: 100%;
      display: flex;
      margin-top: 30px;
}

.contact-info__icon {
      color: #6c757d;
      font-size: 40px;
}

.media-body h3 {
      font-size: 18px;
}

.media-body p {
      color: #6c757d;
      font-size: 16px;

}

@media only screen and (max-width: 420px) {
      .contact_form {
            width: 100% !important;
      }

      .contact_info_data {
            width: 100% !important;
      }

      .form_control_textarea {
            width: 100%;
      }

      .form_control_subject {
            width: 100%;
      }
}

@media only screen and (max-width: 820px) {
      .contact_form {
            width: 100% !important;
      }

      .contact_info_data {
            width: 100% !important;
            padding-left: 0;
      }

      .form_control_textarea {
            width: 100%;
      }

      .form_control_subject {
            width: 100%;
      }
}

@media only screen and (max-width: 1200px) {

      .form_control_textarea {
            width: 100%;
      }

}

@media only screen and (max-width: 1440px) {

      .form_control_textarea {
            width: 100%;
      }

}