#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 320px;
  /* height: 300px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.hexagon {
  width: 250px;
  height: 250px;
  filter: grayscale(100%);
  cursor: pointer;
  transition: transform 0.5s ease;
}

.hexagon:hover {
  opacity: 1;
  filter: grayscale(0%);
  transform: scale(1.5);
  z-index: 999;
}

.hexagon:hover .hexagonContainer {
  background-color: #eee !important;
}

.firstRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.firstRowTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.hexagonContainer {
  padding: 60px 0;
  background-image: -webkit-linear-gradient(0deg, #6c19f6 0%, #f9218d 100%);
}

.on_click {
  display: flex;
  width: 100%;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: width 1.5s ease-in-out;
}
.ant-modal-mask.ant-modal-mask{
  background: #8080802b !important;
}
.ant-modal-close-x{
  display: none !important;
}
.modal_class_head .ant-modal-body{
  padding: 0 !important;
}
.cross_modal {
  background: -webkit-linear-gradient(0deg, #6c19f6 0%, #f9218d 100%);
  color: white !important;
  position: absolute;
  top: 0% !important;
  right: 0px;
  font-size: 24px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 0 !important;
  padding: 3px;
}

.modal_image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.position_modal_image {
  transition: width 1.5s ease-in-out;
}

@media only screen and (max-width: 420px) {
  .position_modal_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cross_modal {
    top: 26%;
  }
  .modal_image {
    height: 50%;
  }
}
