.header_down {
  background: #f4feff;
}

.Home_content_left {
  text-align: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_content_left span {
  display: inline-block;
  color: #2b044d;
  font-size: 22px;
  margin-bottom: 20px;
}

.Home_content_left h1 {
  font-size: 65px;
  font-weight: 700;
  color: #2b044d;
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
  margin-bottom: 32px;
}

.Home_content_left p {
  font-family: "Poppins", sans-serif;
  color: #707b8e;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 54px;
  padding-right: 155px;
  letter-spacing: 2px;
}

.feature_img {
  width: 100%;
}

.feature_section {
  background-color: none;
  padding: 120px 0;
}
.about_feature_section{
  padding-bottom:0 !important;
}
.feature_section_meet_the_team {
  padding: 100px 0 20px 0;
  background: #f4feff;
}

.feature_right_div {
  padding: 24px 62px;
}

.feature_right_div h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 90px;
  color: #2b044d;
  text-align: left;
}

.single-features {
  display: flex;
}

.flaticon_support {
  position: relative;
  color: #fff !important;
  font-size: 30px;
  height: 67px;
  width: 67px;
  display: block;
  line-height: 67px !important;
  border-radius: 50%;
  text-align: center;
  background-image: -moz-linear-gradient(90deg, #e78ae9 0%, #926ef8 100%);
  background-image: -webkit-linear-gradient(90deg, #e78ae9 0%, #926ef8 100%);
}

.features_caption {
  width: 100%;
  text-align: left;
  padding: 0 0 0 46px;
}

.features_caption h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #23182c;
}

.features_caption p {
  font-family: "Poppins", sans-serif;
  color: #707b8e;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}

.feature_text_header {
  padding: 24px 0px;
}

.section-tittle h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 90px;
  color: #2b044d;
}

.flaticon-businessman {
  padding: 26px 0;
  position: relative;
  color: #fff !important;
  font-size: 40px;
  height: 105px;
  width: 105px;
  /* display: block; */
  line-height: 96px !important;
  border-radius: 50%;
  text-align: center;
  background: #e88cea;
  margin-bottom: 42px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.service-area .services-caption {
  background: #fff;
  padding: 22px 20px 22px;
  border: 1px solid transparent;
  border-radius: 7px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  /* height: 370px; */
  margin: 20px 0 0 0;
}

.section-padding2 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.service-icon::before {
  background-image: url(../img/shape/dot-bg.png);
  content: "";
  width: 80px;
  height: 135px;
  position: absolute;
  right: 28%;
  top: 13%;
  z-index: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.services-caption:hover {
  border: 1px solid #b8a2ff;
}

.services-caption:hover .flaticon-businessman {
  background: #835ef8;
}

/* .services-caption.active .service-icon span {
  background: #835ef8;
} */
/* .services-caption.active {
  border: 1px solid #b8a2ff;
} */
.services-caption.active .service-icon::before {
  right: 24%;
}

.services-caption:hover .service-icon::before {
  transition: 0.5s;
  right: 24%;
}
.service-cap{
  margin: 0 0 16px 0;
}
.service-cap h4 a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 27px;
  display: inline-block;
  color: #2b044d;
  text-transform: uppercase;
}

.service-cap h4 a:hover {
  color: #835ef8;
}

.service-cap p {
  display: inline;
  font-family: "Poppins", sans-serif;
  color: #707b8e;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}

.single-cases-info {
  text-align: left;
  padding: 0 0 0 120px;
}

.single-cases-info h3 {
  color: #2b044d;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 20px;
  padding-right: 30px;
}

.contentStyle {
  width: 90%;
  /* opacity: 0.2; */
  margin: 0px 0px 0 284px;
}

.our_customer_main {
  text-align: left;
  width: 100%;
  opacity: 0.2;
  /* margin: 0px 0px 0 284px; */
}

.our_customer_main_meet {
  opacity: 1;
}

.our_customer_main_meet_img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 30%;
}

.devloper_name {
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.devloper_name_desig {
  margin: 18px 0 0 0;
  font-size: 13px;
  color: #646262;

}

.devloper_name_desig_desc {
  text-align: justify;
  font-size: 15px;
}

.slick-current .contentStyle {
  opacity: 2;
}

.image_div_ourcustomer {
  display: flex;
  justify-content: flex-start;
}

.image_div_ourcustomer_team {
  height: 265px;
}

.what-cap h4 a {
  font-size: 22px;
  color: #712fda;
  margin-bottom: 20px;
}

.our_customer_main img {
  width: 100px;
  height: 100px;
}

.slick-current .our_customer_main {
  opacity: 2;
}

.main_customer {
  margin: 0 0 0 432px;
  box-shadow: 0px 0px 9px 0px rgb(131 93 248 / 7%);
  background: #ffff;
  padding: 40px;
}

.main_customer_meet {
  margin: 0 !important;
  border: 15px solid #f9218d;
}

.main_customer_meet_header {
  width: 100%;
  /* height: 850px; */
}

.ant-carousel .slick-dots li button {
  text-indent: -100000px;
  height: 10px !important;
  width: 10px !important;
  border: 0 !important;
  padding: 0 !important;
  margin-right: 8px;
  cursor: pointer !important;
  border-radius: 50% !important;
  background: #f9d8e0 !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #ec5252 !important;
  height: 16px !important;
  width: 16px !important;
  padding-top: 2px !important;
}

.hidetext .slick-dots-bottom {
  display: none !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -50px !important;
}

/* --------------- */
.available-app-area {
  position: relative;
  padding-top: 220px;
  padding-bottom: 220px;
  background-image: -webkit-linear-gradient(0deg, #6c19f6 0%, #f9218d 100%);
}

.section-tittle3 {
  text-align: left;
}

.section-tittle3 h2 {
  font-size: 50px;
  color: #fff;
  margin-bottom: 35px;
  font-weight: 600;
  margin-bottom: 35px;
  line-height: 1.2;
}

.available-app-area .app-caption p {
  color: #fff;
  padding-right: 52px;
  padding-bottom: 26px;
  margin-bottom: 15px;
}

.app-btn {
  /* display: flex; */
}

.app-btn img {
  width: 150px;
  margin: 0 10px 0 0;
}

.app-shape-top {
  position: absolute;
  top: 90px;
  left: 100px;
  width: 55px;
  -webkit-animation: scale-up-center 1s linear infinite both;
  animation: scale-up-center 1s linear infinite both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.app-shape-left {
  width: 160px;
  position: absolute;
  left: 0;
  bottom: 0;
}

/* ----------------------------------- */
.say-something-aera {
  position: relative;
  border-bottom: 1px solid #ffc9fd;
  padding: 90px 0px;
  overflow: hidden;
}

.say-something-aera .say-something-cap h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  color: #282828;
  text-align: left;
}

.radius-btn {
  display: inline-block;
  padding: 30px 43px;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 0;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 15px;
  font-weight: 400;
}

.career_btn {
  padding: 18px 43px !important;
}
.career_btn_diabled{
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.career_btn_diabled:after {
  background: grey !important;
}
.radius-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9218d;
  border-radius: 10rem;
  z-index: -2;
  font-size: 16px;
  font-weight: 400;
}

.radius-btn:before {
  font-size: 16px;
  font-weight: 400;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #da1276;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.radius-btn:hover {
  color: #fff;
}

.radius-btn:hover:before {
  width: 100%;
}

.say-shape1 {
  position: absolute;
  left: -99px;
  bottom: -140px;
  width: 250px;
  animation: rotate-in-center 10s linear infinite both;
}

@keyframes rotate-in-center {
  0% {
    transform: rotate(-360deg);
    opacity: 1;
  }

  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

.say-shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 760px;
}

/* ------------------------------------------ */
.footer-padding {
  padding-top: 150px;
  padding-bottom: 100px;
}

.footer-logo {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.footer-logo img {
  width: 100px;
}

.footer-tittle {
  text-align: left;
}

.footer-area .footer-tittle h4 {
  color: #000;
  font-size: 18px;
  margin-bottom: 48px;
  font-weight: 700;
}

.footer-area .footer-tittle ul li a {
  color: #868c98;
  font-weight: 300;
}

.footer-area .footer-tittle ul li {
  color: #012f5f;
  margin-bottom: 15px;
}

.footer-area .footer-form form {
  position: relative;
}

.footer-area .footer-form form input {
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border: 1px solid #fff;
  background: #f9f9fe;
}

.footer-area .footer-form form .form-icon button {
  position: absolute;
  top: 1px;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 11px 22px;
  background: #8f1bdc;
  line-height: 1;
}

.video_main {
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.Carrer_aply_button {
  width: 100%;
}

.career_bg {
  background-image: url("../img/SOFTOPSTECH.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.position_set {
  background-image: linear-gradient( rgb(201 201 201 / 50%), rgba(201 201 201 / 50%) ),url("../img/video.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 0;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0px; */
}
.span_cursor{
  cursor: pointer;
  color: #e88cea;
}
.span_cursor_less{
  cursor: pointer;
  color: #835ef8;
}
.ant-notification {
  z-index: 9999 !important;
}

/* responsive */
@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 820px) {
  .our_customer_main {
    text-align: center;
  }

  p {
    font-size: 12px !important;
  }

  .Home_content_left h1 {
    font-size: 30px !important;
  }

  .Home_content_left p {
    padding: 0 !important;
  }

  .feature_right_div h2 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .features_caption {
    padding: 0 0 0 12px;
  }

  .features_caption h3 {
    font-size: 18px;
  }

  .features_caption p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .position_set {
    position: unset;
  }

  .video_main {
    opacity: 1;
  }

  .main_customer {
    margin: 0 0 0 1px;
  }

  .image_div_ourcustomer {
    /* height: 265px; */
    justify-content: center;
  }

  .what-cap {
    margin: 16px 0 0 0;
  }

  .what-cap h4 {
    font-size: 15px !important;
  }

  .what-cap p {
    font-size: 12px !important;
  }

  .single-cases-info {
    padding: 0 !important;
  }

  .single-cases-info h3 {
    font-size: 31px;
  }

  .section-tittle h2 {
    font-size: 30px;
    margin-top: 20px;
  }

  .app-btn img {
    width: 80px;
    margin: 0 !important;
  }

  .say-something-aera .say-something-cap h2 {
    font-size: 25px;
  }

  .footer-padding {
    padding-top: 100px;
    padding-bottom: 10px;
  }

  .footer-copy-right {
    margin: 15px 0 0 0;
  }

  .radius-btn {
    font-size: 14px;
  }
  .section-padding2{
    padding-top: 60px;
  }
}

@media only screen and (max-width: 420px) {
  .service-cap h4 a {
    font-size: 12px;
  }

  p {
    font-size: 12px !important;
  }

  .service-icon::before {
    top: 6%;
  }

  .our_customer_main {
    opacity: 2;
  }

  .main_customer {
    margin: 0px !important;
  }

  .form_control_textarea {
    width: 100%;
  }

  .app-img {
    margin: 125px 0 0 0;
  }

  .available-app-area {
    padding: 0 15px !important;
  }

  .feature_section {
    padding: 20px 0;
  }

  .say-something-aera .say-something-cap h2 {
    font-size: 20px;
  }

  .image_div_ourcustomer {
    /* height: 265px; */
    justify-content: center !important;
  }

  .our_cus .slick-dots-bottom {
    bottom: -10px !important;
  }

  .our_customer_main {
    text-align: center;
  }
  .section-padding2{
    padding-top: 60px;
  }
}